<template>
  <div>
    <div class="text-center my-2" style="font-weight: bold">
      {{ $t("page_navigator_name") }}
      ({{ pages }} )
    </div>
    <div class="d-flex">
      <div @click="
        () => {
          this.$emit('goto-first');
        }
      " class="mx-3" style="cursor: pointer">
        {{ $t("page_navigator_first") }}
      </div>
      <!-- Previous Page Button -->
      <div style="cursor: pointer" @click="prevPage">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </div>
      <!-- List of pages -->
      <div class="mx-3" v-for="page in pagesTrim(pages, current_page, 4)" :key="page"
        :class="page === current_page ? 'current-page' : ''" style="cursor: pointer" @click="clickPage(page)">
        {{ page }}
      </div>
      <div class="mx-3" v-show="pages > 10 && current_page + 5 < pages">
        ...
      </div>
      <!-- Next Page Button -->
      <div style="cursor: pointer" @click="nextPage">
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
      </div>
      <!-- Goto last -->
      <div @click="
        () => {
          this.$emit('goto-last');
        }
      " class="mx-3" style="cursor: pointer">
        {{ $t("page_navigator_last") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page Navigator",
  props: {
    pages: {
      type: Number,
      default: 0,
    },
    current_page: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    clickPage(page) {
      this.$emit("click-page", page);
    },
    nextPage() {
      this.$emit("next-page");
    },
    prevPage() {
      this.$emit("prev-page");
    },
    pagesTrim(pages, current_page, limit) {
      if (pages > 10) {
        const trim_pages = [];
        for (let i = current_page - limit; i < current_page + limit; i++) {
          if (i < 1) {
            continue;
          }
          if (i > pages) {
            break;
          }
          trim_pages.push(i);
        }
        console.log("trim_pages", trim_pages, "pages", pages);
        console.log("current_page", current_page, "limit", limit);
        return trim_pages;
      }
      return pages;
    },
  },
};
</script>

<style scoped>
.current-page {
  border: 1px solid black;
  padding-left: 7px;
  padding-right: 7px;
  font-weight: 900;
}
</style>

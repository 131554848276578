<template>
  <div class="theme-dashboard-view">
    <div class="d-flex justify-content-between">
      <!-- Dashboard Title -->
      <h1 v-show="!edit">{{ $t("inventory_create_title") }}</h1>
      <h1 v-show="edit">{{ $t("inventory_update_title") }}</h1>

      <!-- Submit Button -->
      <div class="d-flex" v-show="!loading">
        <div>
          <button class="theme-button mx-3" style="font-weight: 400; height: 50px; width: 150px"
            @click="confirmUpdateImages" v-show="(itemImages.length != itemImageLength) && edit">
            Add new photos
          </button>
        </div>
        <button class="theme-button theme-background-light text-dark"
          style="font-weight: 400; height: 50px; width: 120px" @click="this.edit ? updateProduct() : createProduct()">
          {{ $t("inventory_create_save_button") }}
        </button>
      </div>
    </div>
    <!-- Dashboard Content -->
    <div class="my-4">
      <!-- Results -->
      <div>
        <div class="d-flex justify-content-center">
          <LoadingIndicator :active="loading" />
        </div>
        <div v-show="message">
          <div class="justify-content-center text-center my-4">
            <h5>{{ message }}</h5>
          </div>
        </div>
      </div>

      <!-- Create Product Form -->
      <div v-show="!loading">
        <TabView :tabs="Object.keys(tabs)" :tabText="tabs">
          <!-- General Tab -->
          <template v-slot:General>
            <div class="p-5" style="min-height: 52vh">
              <h4>{{ $t("inventory_create_general_title") }}</h4>
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 col-12">
                  <!-- Item name -->
                  <div class="col-xl-7 col-lg-9 my-3">
                    <label class="my-1">
                      {{ $t("inventory_create_general_item_name") }}
                    </label>
                    <div>
                      <input type="text" v-model="itemName" class="w-100 p-2"
                        style="border: 1px #aaa solid; border-radius: 5px" />
                      <!-- :placeholder="
													$t('inventory_create_general_item_name_placeholder')
												" -->
                    </div>
                  </div>
                  <!-- Item name -->
                  <div class="col-xl-7 col-lg-9 my-3">
                    <label class="my-1"> {{ $t("inventory_create_general_item_description") }} </label>
                    <div>
                      <textarea type="text" v-model="itemDesc" class="w-100 p-2" rows="4"
                        style="border: 1px #aaa solid; border-radius: 5px; resize: none;"></textarea>
                      <!-- :placeholder="
													$t('inventory_create_general_item_name_placeholder')
												" -->
                    </div>
                  </div>
                  <!-- Point of Sale ID -->
                  <div class="col-xl-7 col-lg-9 my-3">
                    <div>
                      <label class="my-1">
                        {{ $t("inventory_create_point_of_sale_id") }}
                      </label>
                    </div>
                    <input type="text" v-model="itemPOSID" class="w-100 p-2"
                      style="border: 1px #aaa solid; border-radius: 5px" />
                  </div>

                  <!-- Point of Sale Name  -->
                  <div class="my-3 col-xl-7 col-lg-9">
                    <div>
                      <label class="my-1">
                        {{ $t("inventory_create_point_of_sale_name") }}
                      </label>
                    </div>
                    <input type="text" v-model="itemPOSName" class="w-100 p-2"
                      style="border: 1px #aaa solid; border-radius: 5px" />
                  </div>
                </div>
                <!-- Right Form Values -->
                <div class="col-lg-6 col-12">
                  <!-- Almondi Quanity -->
                  <div class="my-3 col-xl-7 col-lg-9">
                    <div class="my-1">
                      <label>
                        {{
                          $t("inventory_create_general_item_amoudi_quantity")
                        }}
                      </label>
                    </div>
                    <div class="d-flex" style="border: 1px #aaa solid; border-radius: 5px">
                      <!-- Minus Button -->
                      <span class="px-2 d-flex align-items-center orange-hover" style="cursor: pointer" @click="
                        () => {
                          if (this.itemAmoudiQuantity > 0) {
                            this.itemAmoudiQuantity -= 1;
                          }
                        }
                      ">
                        <i class="fa fa-minus" aria-hidden="true" style="font-size: 2vh"></i>
                      </span>

                      <input type="number" placeholder min="0" v-model="itemAmoudiQuantity"
                        class="text-center w-100 py-2" style="border: none" />
                      <!-- Plus Button -->
                      <span class="px-2 d-flex align-items-center orange-hover" style="cursor: pointer" @click="
                        () => {
                          if (this.itemAmoudiQuantity < 10000) {
                            this.itemAmoudiQuantity += 1;
                          }
                        }
                      ">
                        <i class="fa fa-plus" aria-hidden="true" style="font-size: 2vh"></i>
                      </span>
                    </div>
                  </div>

                  <!-- Nani Quantity -->
                  <div class="my-3 col-xl-7 col-lg-9">
                    <div class="my-1">
                      <label>
                        {{ $t("inventory_create_general_item_nani_quantity") }}
                      </label>
                    </div>
                    <div class="d-flex" style="border: 1px #aaa solid; border-radius: 5px">
                      <!-- Minus Button -->
                      <span class="px-2 d-flex align-items-center orange-hover" style="cursor: pointer" @click="
                        () => {
                          if (this.itemNaniQuantity > 0) {
                            this.itemNaniQuantity -= 1;
                          }
                        }
                      ">
                        <i class="fa fa-minus" aria-hidden="true" style="font-size: 2vh"></i>
                      </span>

                      <!-- Number Input -->
                      <input type="number" placeholder min="0" v-model="itemNaniQuantity" class="text-center w-100 py-2"
                        style="border: none" />

                      <!-- Plus Button -->
                      <span class="px-2 d-flex align-items-center orange-hover" style="cursor: pointer" @click="
                        () => {
                          if (this.itemNaniQuantity < 10000) {
                            this.itemNaniQuantity += 1;
                          }
                        }
                      ">
                        <i class="fa fa-plus" aria-hidden="true" style="font-size: 2vh"></i>
                      </span>
                    </div>
                  </div>
                  <!-- TODO: Create a Number Slider Component -->
                  <!-- Increment -->
                  <div class="my-3 col-xl-7 col-lg-9">
                    <div class="my-1">
                      <label>
                        {{ $t("inventory_create_general_item_increment") }}
                      </label>
                    </div>

                    <div class="d-flex" style="border: 1px #aaa solid; border-radius: 5px">
                      <!-- Minus Button -->
                      <span class="px-2 d-flex align-items-center orange-hover" style="cursor: pointer" @click="
                        () => {
                          if (this.itemIncrement > 0) {
                            this.itemIncrement -= 1;
                          }
                        }
                      ">
                        <i class="fa fa-minus" aria-hidden="true" style="font-size: 2vh"></i>
                      </span>
                      <!-- Number Input -->
                      <input type="number" min="0.00" max="10000.00" step="0.01" v-model="itemIncrement"
                        class="text-center w-100 py-2" style="border: none" />
                      <!-- Plus Button -->
                      <span class="px-2 d-flex align-items-center orange-hover" style="cursor: pointer" @click="
                        () => {
                          if (this.itemIncrement < 10000) {
                            this.itemIncrement += 1;
                          }
                        }
                      ">
                        <i class="fa fa-plus" aria-hidden="true" style="font-size: 2vh"></i>
                      </span>
                    </div>
                  </div>

                  <!-- Unit -->
                  <div class="my-3 col-xl-7 col-lg-9">
                    <div class="my-1">
                      <label>{{
                        $t("inventory_create_general_item_unit")
                      }}</label>
                    </div>
                    <select v-model="itemUnit" class="w-100 p-2" style="border: 1px #aaa solid; border-radius: 5px">
                      <option :value="0">
                        {{ $t("inventory_create_general_item_unit_number") }}
                      </option>
                      <option :value="1">
                        {{ $t("inventory_create_general_item_unit_mass") }}
                      </option>
                      <option :value="2">
                        {{ $t("inventory_create_general_item_unit_volume") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- <div class="w-50 my-3">
                    <TextAreaInput class="" title="Item Description"/>
							</div>-->
            </div>
          </template>

          <!-- Category Tab -->
          <template v-slot:Category>
            <div class="p-5" style="min-height: 52vh">
              <h4>{{ $t("inventory_create_category_title") }}</h4>
              <!-- Picked Category -->
              <div class="my-4">
                <p class="m-0">
                  {{ $t("inventory_create_category_selected_category") }}
                </p>
                <!-- Category Picker -->
                <div class="border p-3 rounded selectBox d-flex justify-content-between" @click="toggleCategoryList"
                  style="cursor: pointer">
                  <div>
                    {{
                      categoryList.length > 0
                        ? categoryList[selectedCategory].name
                        : "none"
                    }}
                  </div>

                  <!-- Down Icon -->
                  <div class="d-flex align-items-center col-1 justify-content-center">
                    <i class="fa" :class="
                      displayCategoryList
                        ? 'fa-chevron-down'
                        : 'fa-chevron-left'
                    " aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <!-- {{ itemCategory }} -->
              <!-- Category List -->
              <div class="bg-light" v-show="displayCategoryList">
                <div class="d-flex justify-content-center py-4">
                  <input type="search" placeholder="Search" class="p-2" style="width: 600px"
                    v-model="CategorySearchText" @keydown.enter="getCategories(1)" />
                  <button class="theme-background-primary text-light py-2 px-3" style="border: none"
                    @click="getCategories(1)">
                    <i class="fa fa-search"></i>
                  </button>
                </div>

                <div class="border">
                  <!-- Category list-->
                  <div class="d-flex flex-wrap">
                    <div v-for="(category, index) in categoryList" :key="index"
                      class="mx-3 my-2 rounded d-flex category align-items-center justify-content-center"
                      :class="index === selectedCategory ? 'selected' : ''" @click="
                        () => {
                          this.itemCategory = category.id;
                          this.selectedCategory = index;
                          this.displayCategoryList = false;
                        }
                      ">
                      <div>
                        <div class="h5" style="font-weight: 500">
                          {{ category.name }}
                        </div>

                        <div>
                          {{ category.description }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pagination -->
                  <div class="d-flex justify-content-center py-3">
                    <PageNav :pages="category_pages" :current_page="category_current_page" @click-page="getCategories"
                      @next-page="nextPage" @prev-page="prevPage" @goto-first="gotoFirstPage"
                      @goto-last="gotoLastPage" />
                  </div>
                </div>
              </div>

              <!-- Tags Input Form -->
              <div class="form-group my-3 col-xl-3 col-lg-6">
                <div class="my-1">
                  {{ $t("category_fields_tags") }}
                </div>

                <div class="p-2 d-flex flex-wrap">
                  <div class="theme-background-secondary border rounded d-flex mx-2" v-for="(tag, index) in itemTags"
                    :key="index">
                    <span class="p-2 mx-1">{{ tag }}</span>

                    <span class="px-1" @click="removeTag(index)" style="cursor: pointer">x</span>
                  </div>
                </div>
                <input type="text" @keydown.enter="addTag" v-model="tagText"
                  :placeholder="$t('category_fields_tags_placeholder')" class="p-2 w-100"
                  style="border: 1px #aaa solid; border-radius: 5px" />
              </div>
            </div>
          </template>

          <!-- Pricing Tab -->
          <template v-slot:Price>
            <div class="p-5" style="min-height: 52vh">
              <h4>{{ $t("inventory_create_price_title") }}</h4>
              <!-- Set Price -->
              <div class="my-3 col-xl-3 col-lg-6">
                <div class="my-1">
                  <label>
                    {{ $t("inventory_create_price_option_title") }}
                  </label>
                </div>
                <input type="number" min="0.00" max="10000.00" step="0.01" v-model="itemPrice" class="p-2 w-100"
                  style="border: 1px #aaa solid; border-radius: 5px" />
              </div>

              <!-- Max Orders -->
              <div class="my-3 col-xl-3 col-lg-6">
                <div class="my-1">
                  <label>
                    {{ $t("inventory_max_order") }}
                  </label>
                </div>
                <input type="number" min="0.00" max="10000.00" step="0.01" v-model="itemMaximumOrderQuantity"
                  class="p-2 w-100" style="border: 1px #aaa solid; border-radius: 5px" />
              </div>
              <!-- Min Orders -->
              <div class="my-3 col-xl-3 col-lg-6">
                <div class="my-1">
                  <label>
                    {{ $t("inventory_min_order") }}
                  </label>
                </div>
                <input type="number" min="0.00" max="10000.00" step="0.01" v-model="itemMinimumOrderQuantity"
                  class="p-2 w-100" style="border: 1px #aaa solid; border-radius: 5px" />
              </div>
              <!-- Taxable -->
              <div class="my-3 col-xl-3 col-lg-6">
                <div class="my-1">
                  <label>{{ $t("inventory_create_taxable") }}</label>
                </div>
                <select v-model="itemTaxable" class="w-100 p-2" style="border: 1px #aaa solid; border-radius: 5px">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>

              <!-- Active/Inactive -->
              <div class="my-3 col-xl-3 col-lg-6">
                <div class="my-1">
                  <label>{{ $t("inventory_create_product_active") }}</label>
                </div>
                <select v-model="itemStatus" class="w-100 p-2" style="border: 1px #aaa solid; border-radius: 5px">
                  <option :value="true">Active</option>
                  <option :value="false">Inactive</option>
                </select>
              </div>
              <!-- Set Discount Dates -->
              <!-- <div class="my-3">
								<p class="m-0" style="font-weight: bold">
									Discount
								</p>
								<div class="my-2">
									<div><label>Discount Start Date</label></div>
									<input type="date" />
								</div>
								<div class="my-2">
									<div>
										<label>Discount Price</label>
									</div>
									
									<input
										type="number"
										min="0.00"
										max="10000.00"
										step="0.01"
									/>
								</div>
								<div class="my-2">
									<div><label>Discount End Date</label></div>
									<input type="date" />
								</div>
							</div>-->
            </div>
          </template>

          <!-- Image Tab -->
          <template v-slot:Image>
            <div class="p-5" style="min-height: 52vh">
              <div class="d-flex justify-content-between">
                <h4>
                  {{ $t("inventory_create_image_title") }}
                </h4>

                <label class="theme-button d-flex align-items-center justify-content-center"
                  style="width: 170px; height: 60px">
                  <i class="fa fa-image mx-2" style="font-size: 20px"></i>
                  {{ $t("inventory_create_image_select_text") }}
                  <input ref="imageFile" class="m-3 d-none" type="file" name="image" @change="setImage"
                    accept="image/*" />
                </label>
              </div>
              <!-- Image Viewer -->
              <div class="my-3 p-2 d-flex flex-wrap">
                <div :class="{
                  'd-flex': itemImages.length === 0,
                  'd-none': itemImages.length !== 0,
                }" class="w-100 align-items-center justify-content-center">
                  <p>
                    {{ $t("inventory_create_image_alt") }}
                  </p>
                </div>
                <!-- Confirm Remove Dialog for images -->
                <ConfirmationDialog ref="ConfirmRemoveImageDialog" @confirm="removeImage"
                  message="Are you sure you want to remove image" />
                <!-- Confirm Update Images Dialog -->
                <CustomDialog ref="ConfirmUpdateImageDialog" @confirm="updateProductImages"
                  :customButtons="['Overwrite', 'Append']" message="New Images have been added" />
                <!-- Image Generator -->
                <div v-for="(image, index) in itemImages" :key="index">
                  <div class="d-flex shadow m-2 position-relative" style="width: 350px">
                    <!-- Top of image -->
                    <div class="position-absolute d-flex justify-content-end p-2"
                      style="width: 350px; font-size: 20px; z-index: 3">
                      <!-- Remove button -->
                      <i class="fa fa-times" @click="confirmRemoveImage(index)" style="cursor: pointer"></i>

                      <!-- New Product Image Overlay -->

                      <!-- New Ribbon -->
                      <div class="ribbon" v-show="itemImageLength <= index">
                        <span>New</span>
                      </div>
                    </div>

                    <!-- Color overlay -->
                    <div class="position-absolute w-100 h-100" v-show="itemImageLength <= index"
                      style="background: rgba(150, 255, 250, 0.4); z-index: 2"></div>

                    <!-- Image -->
                    <img class="w-100 h-100" :src="image" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script>
// Components

import TabView from "@/components/TabView.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageNav from "@/components/PageNav.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import CustomDialog from "@/components/CustomDialog.vue";

// Services
import SearchService from "@/services/search.js";
import CategoryService from "@/services/category.js";
import ProductService from "@/services/products.js";

export default {
  components: {
    ConfirmationDialog,
    CustomDialog,
    TabView,
    LoadingIndicator,
    PageNav,
  },
  data() {
    return {
      // Product Data
      itemName: "",
      itemPOSID: 0,
      itemPOSName: "",
      itemDesc: "",
      itemPrice: 0.0,
      itemAmoudiQuantity: 0,
      itemNaniQuantity: 0,
      itemSaraQuantity: 0,
      itemIncrement: 0,
      itemTaxable: true,
      itemStatus: true,
      itemUnit: 0,
      itemMaximumOrderQuantity: 0,
      itemMinimumOrderQuantity: 0,
      itemCategory: 1,
      itemImage: "",
      itemImages: [],
      itemTags: [],

      // Display Data

      tabs: {
        // Translated Tab Text
        General: this.$t("inventory_create_general_title"),
        Category: this.$t("inventory_create_category_title"),
        Price: this.$t("inventory_create_price_title"),
        Image: this.$t("inventory_create_image_title"),
      },
      itemImageLength: 0,

      // Category Parameters
      categoryList: [],
      tagText: "",
      displayCategoryList: false,
      selectedCategory: 0,
      category_current_page: 1,
      category_pages: 0,
      CategorySearchText: "",

      // Response Indicator
      loading: false,
      message: "",

      // Edit mode
      edit: false,
    };
  },

  methods: {
    // Creates the product
    createProduct() {
      this.loading = true;
      this.message = "";

      // Check if valid inputs
      if (!this.createProductValidate()) {
        this.loading = false;
        return;
      }
      ProductService.createProduct(this.toJson()).then(
        (response) => {
          this.loading = false;
          this.message = response.data.message;
          this.emptyValues();
        },
        (error) => {
          this.loading = false;
          console.log(error.response);
          this.message = error.response.data.message;
        }
      );
    },
    // Update the product
    updateProduct() {
      this.loading = true;
      this.message = "";

      if (!this.createProductValidate()) {
        this.loading = false;
        return;
      }

      if (this.itemImages.length != this.itemImageLength) {
        this.loading = false;
        this.confirmUpdateImages();
        return;
      }

      ProductService.updateProduct(this.$route.params.id, this.toJson()).then(
        (response) => {
          this.loading = false;
          this.message = "Product Updated!!";
        },
        (error) => {
          this.loading = false;
          console.log(error.response);
          this.message = error.response.data.message;
        }
      );
    },
    confirmUpdateImages() {
      this.$refs.ConfirmUpdateImageDialog.showConfirmDialog();
    },
    // Update the images on the server
    updateProductImages(completeButton) {
      this.itemImageLength = this.itemImages.length;
      ProductService.updateProductImages(
        this.$route.params.id,
        this.itemImages,
        completeButton == "Overwrite"
      ).then(
        (response) => {
          console.log("response", response);
        },
        (error) => {
          console.log("update image error", error);
        }
      );
    },
    // Set the image
    setImage(e) {
      const selectedImage = e.target.files[0];
      const reader = new FileReader();

      // Set itemImage once processing is done
      reader.onload = (e) => {
        this.itemImages.push(e.target.result);
        this.$refs.imageFile.value = "";
      };

      reader.readAsDataURL(selectedImage);
    },
    // Base 64 checker
    base64Checker(message) {
      const firstHeader = message.split(",");
      const secondHeader = firstHeader[0].split(";");
      if (secondHeader[secondHeader.length - 1] == "base64") {
        return true;
      } else {
        return false;
      }
    },
    // Validates the product creation
    createProductValidate() {
      if (this.itemName === "") {
        this.message = "Item name should not be empty";
        return false;
      }
      if (this.itemQuantity < 1) {
        this.message = "Item Quantity cannot be zero";
        return false;
      }
      return true;
    },

    // confirmRemoveImage(index) {
    //   this.$refs.ConfirmRemoveImageDialog.showConfirmDialog(index);
    // },

    removeImage(index) {
      // If the image is a url (not base64 which is for local upload),
      // send a remove request
      if (!this.base64Checker(this.itemImages[index])) {
        // Call function
        this.loading = true;
        this.message = "";
        ProductService.removeImage(
          this.$route.params.id,
          this.itemImages[index]
        ).then(
          (response) => {
            this.loading = false;
            this.message = "Image has been removed!";
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
      // Remove image from this.itemImages
      this.itemImages.splice(index, 1);
    },

    //---- Data Functions ----
    // Empties values once a product has been created
    emptyValues() {
      // General
      this.itemName = "";
      this.itemDesc = "";
      this.itemPOSID = 0;
      this.itemPOSName = "";

      // Quantity
      this.itemAmoudiQuantity = 0;
      this.itemNaniQuantity = 0;
      this.itemSaraQuantity = 0;
      this.itemIncrement = 0;
      this.itemUnit = 0;
      this.itemMaximumOrderQuantity = 0;
      this.itemMinimumOrderQuantity = 0;

      // Price
      this.itemPrice = 0.0;
      this.itemTaxable = true;
      this.itemStatus = true;

      // Category
      this.itemCategory = 0;
      this.selectedCategory = 0;
      this.itemTags = [];

      // Images
      this.itemImages = [];
    },
    // Assign values to fields
    assignValues(product) {
      // General
      this.itemName = product.name;
      this.itemDesc = product.description;
      this.itemPOSID = product.pos_id;
      this.itemPOSName = product.pos_name;

      // Quantity
      this.itemAmoudiQuantity = product.amoudi_quantity;
      this.itemNaniQuantity = product.nani_quantity;
      this.itemSaraQuantity = product.sara_quantity;
      this.itemIncrement = product.increment;
      this.itemUnit = product.unit;
      this.itemMaximumOrderQuantity = product.maximum_order_quantity;
      this.itemMinimumOrderQuantity = product.minimum_order_quantity;

      // Price
      this.itemPrice = product.price;
      this.itemTaxable = product.taxable;
      this.itemStatus = product.visible;

      // Category
      this.itemCategory = product.category_id;
      this.itemTags = product.tags;

      // Images
      this.itemImages = product.img;
      this.itemImageLength = product.img.length;
    },
    // Convert product to json
    toJson() {
      return {
        // General
        name: this.itemName,
        description: this.itemDesc,
        pos_id: this.itemPOSID,
        pos_name: this.itemPOSName,

        //Quantity
        amoudi_quantity: this.itemAmoudiQuantity,
        nani_quantity: this.itemNaniQuantity,
        increment: this.itemIncrement,
        minimum_order_quantity: this.itemMinimumOrderQuantity,
        maximum_order_quantity: this.itemMaximumOrderQuantity,
        unit: this.itemUnit,

        // Price
        price: this.itemPrice,
        taxable: this.itemTaxable,
        visible: this.itemStatus,

        // Category
        category: this.itemCategory,
        tags: this.itemTags,

        // Images
        img: this.itemImages,
      };
    },
    // ----------------------

    // ---- Category View Functions ----
    // Get the category
    getCategoryIndex(id) {
      for (var i = 0; i <= this.categoryList.length; i++) {
        if (this.categoryList[i] != null) {
          if (this.categoryList[i].id === id) {
            return i;
          }
        }
      }
      return 0;
    },
    // Toggle the category list
    toggleCategoryList() {
      this.displayCategoryList = !this.displayCategoryList;
    },
    // Add Tags
    addTag() {
      this.itemTags.push(this.tagText);
      this.tagText = "";
    },
    // Remove Tags
    removeTag(index) {
      this.itemTags.splice(index, 1);
      console.log(this.itemTags);
    },
    confirmRemoveImage(index) {
      this.$refs.ConfirmRemoveImageDialog.showConfirmDialog(index);
    },
    removeImage(index) {
      // If the image is a url (not base64 which is for local upload),
      // send a remove request
      if (!this.base64Checker(this.itemImages[index])) {
        // Call function
        this.loading = true;
        this.message = "";
        ProductService.removeImage(
          this.$route.params.id,
          this.itemImages[index]
        ).then(
          (response) => {
            this.loading = false;
            this.message = "Image has been removed!";
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
      // Remove image from this.itemImages
      this.itemImages.splice(index, 1);
    },
    // Get Categories
    getCategories(page) {
      // If the category is search display search results
      if (this.CategorySearchText.length !== 0) {
        this.CategorySearch(page);
        return;
      }
      // If not search just let user browse through pagination
      this.loading = true;
      this.message = "";

      CategoryService.getCategories(page).then(
        (response) => {
          this.loading = false;
          this.categoryList = response.data.data.categories;
          this.category_current_page = page;
          this.category_pages = response.data.data.total_pages;
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    // Category Search
    CategorySearch(page) {
      this.loading = true;
      this.message = "";
      SearchService.SearchProduct(
        page,
        {
          search: this.CategorySearchText,
          category_id: null,
          tags: [],
          price_g: null,
          price_l: null,
        },
        "category"
      ).then(
        (response) => {
          this.loading = false;
          this.category_current_page = page;
          this.categoryList = response.data.data.categories;
          this.category_pages = response.data.data.total_pages;
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    // --------------------------

    // ------- Category page navigation -----
    // Page Navigation
    nextPage() {
      if (this.category_current_page != this.category_pages) {
        this.getCategories(this.category_current_page + 1);
      }
    },
    prevPage() {
      if (this.category_current_page != 1) {
        this.getCategories(this.category_current_page - 1);
      }
    },
    gotoFirstPage() {
      if (this.category_current_page != 1) {
        this.getCategories(1);
      }
    },
    gotoLastPage() {
      if (this.category_current_page != this.category_pages) {
        this.getCategories(this.category_pages);
      }
    },
  },
  mounted() {
    // Check if there is a id and set to edit (update) mode
    if (this.$route.params.id != null) {
      this.edit = true;

      // Get the product and set the values
      this.loading = true;
      this.message = "";
      ProductService.getSingleProduct(this.$route.params.id).then(
        (response) => {
          this.loading = false;
          const product = response.data.data;
          this.assignValues(product);

          // Get the categories for the product
          const page = Math.ceil(this.itemCategory / 15);
          CategoryService.getCategories(page).then((response) => {
            this.categoryList = response.data.data.categories;
            this.category_current_page = page;
            this.category_pages = response.data.data.total_pages;
            this.selectedCategory = this.getCategoryIndex(this.itemCategory);
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    } else {
      // Get the categories for the product
      this.getCategories(1);
    }
  },
  updated() {
    if (this.edit) {
      console.log("Update");
      if (this.$route.params.id == null) {
        this.edit = false;
        this.emptyValues();
      }
    }
  },
};
</script>

<style scoped>
/* Input boxes hide number spinner */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.orange-hover:hover {
  background-color: orange;
}

.category {
  cursor: pointer;
  width: 200px;
  height: 200px;
}

.category:hover {
  background-color: rgba(150, 150, 150, 0.07);
}

.selectBoxSelected {
  background-color: gray;
}

.selectBox:hover {
  background-color: #eee;
}

.selected {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px rgb(165, 165, 165) solid;
}
</style>
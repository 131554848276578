import axios from "axios";
import authHeader from "../helpers/auth-header";

const API_CATEGORY_URL = process.env.VUE_APP_API_URL + "category";

class CategoryService {
	getCategories(page) {
		return axios.get(API_CATEGORY_URL + "/admin/paginated/" + page, {
			headers: authHeader(),
		});
	}

	getSingleCategory(id) {
		// console.log(API_CATEGORY_URL + "/admin/" + id);
		return axios.get(API_CATEGORY_URL + "/admin/" + id, {
			headers: authHeader(),
		});
	}

	createCategory(category) {
		return axios.post(API_CATEGORY_URL + "/", category, {
			headers: authHeader(),
		});
	}

	updateCategory(id, category) {
		return axios.put(API_CATEGORY_URL + "/" + id, category, {
			headers: authHeader(),
		});
	}

	deleteCategory(id) {
		return axios.delete(API_CATEGORY_URL + "/" + id, { headers: authHeader() });
	}
}

export default new CategoryService();

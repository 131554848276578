<template>
  <div>
    <Popup ref="ConfirmDialog">
      <template v-slot:body>
        <div class="text-center h3 p-4">
          {{ message }}
        </div>
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-content-around">
          <button @click="sendConfirm" class="btn btn-outline-primary">
            Yes
          </button>
          <button @click="closeConfirmDialog" class="btn btn-outline-danger">
            No
          </button>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Popup from "@/components/Popup.vue";
export default {
  name: "ConfirmDialog",
  props: {
    message: {
      type: String,
      default: "Are you sure?",
    },
  },
  data() {
    return {
      value: null,
    };
  },
  components: {
    Popup,
  },
  methods: {
    showConfirmDialog(confirmValue) {
      this.value = confirmValue;
      this.$refs.ConfirmDialog.showPopup();
    },
    closeConfirmDialog() {
      this.$refs.ConfirmDialog.closePopup();
    },
    sendConfirm() {
      this.$emit("confirm", this.value);
      this.$refs.ConfirmDialog.closePopup();
    },
  },
};
</script>

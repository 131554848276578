<template>
	<div class="popup-background" v-show="PopupActive">
		<div
			class="popup"
			:style="{ height: height + 'px', width: width + 'px' }"
		>
			<div class="popup-header d-flex justify-content-between p-4">
				<span>
					<h3>{{ title }}</h3>
				</span>
				<span @click="closePopup" class="closeButton">
					<i class="fa fa-window-close" aria-hidden="true"></i>
				</span>
			</div>
			<div class="popup-body">
				<slot name="body"></slot>
			</div>
			<div class="popup-footer">
				<slot name="footer"></slot>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Popup",
	props: {
		title: {
			type: String,
			default: "",
		},
		width: {
			type: Number,
			default: 600,
		},
		height: {
			type: Number,
			default: 300,
		},
	},
	data() {
		return {
			PopupActive: false,
		};
	},
	methods: {
		closePopup() {
			this.PopupActive = false;
		},
		showPopup() {
			this.PopupActive = true;
		},
	},
};
</script>
<style scoped>
.popup-background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.popup {
	min-height: 200px;
	min-width: 10px;
	border-radius: 20px;
	max-height: 100%;
	max-width: 100%;
	background-color: white;
	overflow: auto;
}

.closeButton {
	font-size: 20px;
}
.closeButton:hover {
	cursor: pointer;
	transform: scale(1.5);
}
</style>
import axios from "axios";
import authHeader from "../helpers/auth-header";

const API_SEARCH_URL = process.env.VUE_APP_API_URL + "search";

class SearchService {
  SearchProduct(page, query, type) {
    if (Object.keys(query).length > 0) {
      const url =
        type === "product"
          ? API_SEARCH_URL + "/admin/" + page + "?product=true&category=false"
          : API_SEARCH_URL + "/admin/" + page + "?product=false&category=true";
      return axios.post(url, query, {
        headers: authHeader(),
      });
    } else {
      this.$router.push("/search");
    }
  }

  SearchOrder(page, query) {
    return axios.post(API_SEARCH_URL + "/order/" + page, query, {
      headers: authHeader(),
    });
  }
}

export default new SearchService();

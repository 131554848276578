<template>
  <div>
    <!-- Tab Navigation -->
    <Tabs :tabs="tabs" @tab-click="setActive" :active="active" :tabText="tabText"/>

    <!-- View Body -->
    <div class="border shadow-sm">
      <div v-for="(tab, index) in tabs" :key="index" v-show="index == active">
        <slot :name="tab"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/Tabs.vue";

export default {
  name: "TabView",

  components: {
    Tabs,
  },
  props: {
    tabs: {
      type: Array,
      default: [],
    },
    tabText: {
      type: Object,
      default: {},
    }
  },

  data() {
    return {
      active: 0,
    };
  },

  methods: {
    setActive(index) {
      this.active = index;
    },
  },
};
</script>

<style>
</style>
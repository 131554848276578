<template>
	<nav class="">
		<ul class="nav nav-tabs" style="overflow-y: auto">
			<li v-for="(tab, index) in tabs" :key="index" class="nav-item">
				<p
					class="nav-link px-5 py-2 text-dark"
					:class="active == index ? 'active' : ''"
					@click="clickTab(index)"
					style="cursor: pointer; margin: 0"
				>
					{{ getTab(tab) }}
				</p>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: "Tabs",
	props: {
		tabs: {
			type: Array,
			default: [],
		},
		active: {
			type: Number,
			default: 0,
		},
		tabText: {
			type: Object,
			default: {},
		},
	},
	methods: {
		clickTab(index) {
			this.$emit("tab-click", index);
		},
		getTab(tab) {
			if (Object.keys(this.tabText).length == 0) {
				return tab;
			}
			return this.tabText[tab];
		},
	},
};
</script>

<style>
.active {
	color: lightgrey;
}
</style>
